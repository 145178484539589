import "./intro.css";
import Paolo from '../../img/me.webp';
import Es from '../../img/es.webp';
import En from '../../img/en.webp';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';



const LanFlag = styled.div`
height:10px;
width:10px;
display:flex;
flex-direction:row;
position:relative;
left:5px;
`

const Intro = () => {

    const changeLanguage = (ln) =>{
        
        return () =>{
            i18n.changeLanguage(ln);
            
        }
    }


  const {t, i18n } = useTranslation();

    return (
        <div className="i">
        <LanFlag>
                <span ><img onClick={changeLanguage("es")} className="bandera" src={Es} alt="es"/></span>
                <span onClick={changeLanguage("en")}><img className="bandera" src={En} alt="en"/></span>
        </LanFlag>
            <div className="i-left">
                <div className="i-left-wrapper">
                    <h1 className="i-name">Paolo Carretero</h1>
                    <div className="i-title">
                        <div className="i-title-wrapper">
                            <div className="i-title-item">{t("i-title-item-1")}</div>
                            <div className="i-title-item">{t("i-title-item-2")}</div>
                            <div className="i-title-item">{t("i-title-item-3")}</div>
                            <div className="i-title-item">{t("i-title-item-4")}</div>
                        </div>
                    </div>
                    <div className="i-desc">
                  <p>{t("greeting")}</p><br></br>
                
                  <p>{t("description")}</p><br></br>
                   
                  <p>{t("cta")}</p>
                    
                    </div>
                </div>
            </div>
            <div className="i-right">
            <div className="i-bg"></div>
            <img src={Paolo} alt="" className="i-img"/>
            </div>

        </div>
    )
}


export default Intro