import './productList.css';
import Product from '../product/Product';
import { products } from  '../../data';
import { useTranslation } from 'react-i18next';



const ProductList = () => {
    
    const { t, i18n } = useTranslation();


    return (
        <div className="pl">
            <div className="pl-texts">
                <h2 className="pl-title">  {t("pl-title")}</h2>
                <p className="pl-desc">
                {t("pl-desc")} <a href="../../../pandemojic.html" target="blank"><span id="emoji">&#128515;</span></a>
              </p>
            </div>
            <div className="pl-list">
               {products.map((item) => ( 
                    <Product key={item.id} img={item.img} link={item.link}/>
                ))} 
            </div>
        </div>
    )
    
}

export default ProductList
