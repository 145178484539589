
import About from "./components/intro/about/About";
import Intro from "./components/intro/Intro";
import ProductList from "./components/productList/ProductList";
import Contact from "./components/contact/Contact";
import Toggle from "./components/toggle/Toggle";
import Footer from "./components/footer/Footer";
import { ThemeContext } from "./context";
import { useContext } from 'react';
import Services from "./components/services/Services";



const App = () => {

   const theme = useContext(ThemeContext)
   const darkMode = theme.state.darkMode;

  return ( 
  
  <div style={{ backgroundColor: darkMode ? "#222" : "white", color: darkMode && "white",}}>

    <Toggle/>
    <Intro />
    <About />
    <ProductList/>
    <Services/>
    <Contact/>
    <Footer/> 
    
  </div>

  )
};

export default App;