import React, { useEffect, useRef, useState } from 'react';
import Rated from "../../../img/jsc.webp";
import Paolo from "../../../img/paolo2.webp";
import { ThemeContext } from '../../../context';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import './about.css';

const About = () => {
    const theme = useContext(ThemeContext);
    const { t, i18n } = useTranslation();
    const containerRef = useRef(null);
    const [animate, setAnimate] = useState(false);
    const observerRef = useRef(null); 

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.4
        };


        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !animate) {
                    setTimeout(() => {
                        setAnimate(true);
                    }, 100);
                }
            });
        };

        const observerInstance = new IntersectionObserver(observerCallback, options);
        observerRef.current = observerInstance; 

        const currentContainerRef = containerRef.current; 

        if (currentContainerRef && !animate) {
            observerInstance.observe(currentContainerRef);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect(); 
            }
        };
    }, [animate]);




    return (
        <div className={`a ${animate ? 'animate-container' : ''}`} ref={containerRef}>
            <div className="a-left">
                <div className={`a-card bg ${animate ? 'animate-card-img' : ''}`}></div>
                <div className={`a-card ${animate ? 'animate-card-img' : ''}`}>
                    <img src={Paolo} alt="" className={`a-img ${animate ? 'animate-card-img' : ''}`} />
                </div>
            </div>
            <div className={`a-right ${animate ? 'animate-text' : ''}`}>
                <h1 className="a-title" >{t("about")}</h1>
                <p className={"a-sub"}>{t("a-sub")}</p>
                <p className={"a-desc"}>{t("a-desc-1")}</p>
                <p className={"a-desc"}>{t("a-desc-2")}</p>
                <p className={"a-desc"}>{t("a-desc-3")}</p>
                <div className="a-award">
                    <img src={Rated} alt="" className="a-award-img" style={{ display: theme.state.darkMode ? 'none' : 'block' }} />
                    <div className="a-award-texts">
                        <a href="https://www.upwork.com/freelancers/~01a36c2851ab7dd24a" target="blank">
                            <h4 className={`a-award-title ${animate ? 'animate-text' : ''}`} style={{ color: theme.state.darkMode ? '#59b256' : 'green' }}>{t("a-award-title")}&nbsp;👈</h4>
                        </a>
                        <p className={`a-award-desc ${animate ? 'animate-text' : ''}`}>{t("a-award-desc-1")}</p>
                        <br></br>
                        <p>{t("a-award-desc-2")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
