import "./product.css"
import styled from 'styled-components';

const Circle = styled.div`

width: 6px;
 height: 6px;
 border-radius: 50%;
 margin: 3px;
 background-color: ${(props) => props.color};
 opacity:70%;

`


const Product = ({ img, link }) => {
    return (
        <div className="p">
            <div className="p-browser">
               <Circle color="red"/>
               <Circle color="yellow" />
               <Circle color="lightgreen" />

            </div>
            <a href={link} target="_blank" rel="noreferrer">
                <img src={img} alt={img} className="p-img" />

            </a>
        </div>
    )
}

export default Product
