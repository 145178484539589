import React from 'react'
import './Services.css'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

const Services = () => {

    const { t, i18n } = useTranslation();
    
    const [animate, setAnimate] = useState(false);
    const observerRef = useRef(null);
    const containerRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.4
        };


        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !animate) {
                    setTimeout(() => {
                        setAnimate(true);
                    }, 100);
                }
            });
        };

        const observerInstance = new IntersectionObserver(observerCallback, options);
        observerRef.current = observerInstance;

        const currentContainerRef = containerRef.current;

        if (currentContainerRef && !animate) {
            observerInstance.observe(currentContainerRef);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [animate]);



    return (
        <div className={`s`} ref={containerRef}>
            <div className={`s-container ${animate ? 'animate' : ''}`}>
                <h1 className="s-title">{t("s-title")}</h1>
                <div className="s-flex">
                    <div className="s-left">
                        <h2 className="s-subtitle">{t("s-desc-1")} 📝</h2>
                        <ul>
                            <li>{t("s-desc-1-a")}</li>
                            <li>{t("s-desc-1-b")}</li>
                            <li>{t("s-desc-1-c")}</li>
                            <li>{t("s-desc-1-d")}</li>
                            <li>{t("s-desc-1-e")}</li>
                            <li>{t("s-desc-1-f")}</li>
                        </ul>
                    </div>
                    <div className="s-right">
                        <h2 className="s-subtitle">{t("s-desc-2")} 💻</h2>
                        <ul>
                            <li>HTML, CSS, JavaScript</li>
                            <li>React</li>
                            <li>Node.js</li>
                            <li>TypeScript</li>
                            <li>Express</li>
                            <li>MySQL</li>
                            <li>Web Audio API</li>
                            <li>Bootstrap</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services