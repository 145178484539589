import './footer.css';
import styled from 'styled-components';
import { Facebook, Instagram, GitHub, LinkedIn } from "@material-ui/icons";


const SocialContainer = styled.div`

height:50px;
width:250px;
color: gray;
position:relative;
top:5px;

@media screen and (max-width:480px){
    width:250px;
   
   
}
`


function Footer() {
    return (

          <div className="wrapper">
                <SocialContainer>
                    <a href="https://www.linkedin.com/in/p%C3%A1olo-carretero-brandes-68632896/" target="_blank" rel="noreferrer"> <LinkedIn style={{ fontSize: '40px' }} /></a>
              
                    <a href="https://github.com/PaoloC997" target="_blank" rel="noreferrer"><GitHub style={{ fontSize: '40px' }} /></a>
              
                    <a href="https://www.facebook.com/PaulJones9/" target="_blank" rel="noreferrer"> <Facebook style={{ fontSize: '40px' }} /></a>
               
                    <a href="https://www.instagram.com/paoloc997/" target="_blank" rel="noreferrer"><Instagram style={{ fontSize: '40px' }} /></a>
                </SocialContainer>
            </div>
          
    )
}

export default Footer;
