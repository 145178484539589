 import Capsa from './img/capsa.webp';
 import Carol from './img/carol.webp';
 import Hi from './img/hifi.webp';
 import Mnactec from './img/mnactec.webp';
 import Acoustic from './img/acoustic.webp';
 import Plataforma from './img/plataforma.webp'


export const products = [
  {
    id: 1,
    img: Acoustic,
    link: "https://acousticheritagecollective.org",
  },
  {
    id: 2,
    img: Plataforma,
    link: "https://acousticheritagecollective.org/plataformaespana/index.html",
  },
  {
      id: 3,
      img: Capsa ,
      link: "https://lacapsa.org/rescatem-el-patrimoni-acustic-i-sonor-del-prat/",
  },
  {
      id: 4,
      img: Carol,
      link: "https://www.carolcentre.com",
  },
  {
      id: 5,
      img: Mnactec,
      link: "https://www.patrimoniacustic.com/mnactec/index.html",
  },
  {
      id: 6,
      img: Hi,
      link: "../hifilofi/index.html",
    },
  
  ];