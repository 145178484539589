import './contact.css';
import Phone from '../../img/phone.webp';
import Email from '../../img/email.webp';
import Address from '../../img/address.webp';
import { useRef } from 'react';
import { useState } from 'react';
import emailjs from '@emailjs/browser';
import {useTranslation} from 'react-i18next';

const Contact = () => {

    const formRef = useRef();
    const [done, setDone] = useState(false);

    const handleSubmit = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_03lkd6j', 'template_ar4pxsp', formRef.current, 'X3z2x6YMMPpKMthom')
        .then((result) => {
            console.log(result.text);
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });

    }

    const {t, i18n } = useTranslation();



    return (

        
        <div className="c">
            <div className="c-bg"></div>
            <div className="c-wrapper">
                <div className="c-left">
                    <h1 className="c-title">
                    {t("contact")}
                        </h1>
                    <div className="c-info">
                        <div className="c-info-item">
                            <img src={Phone} alt="" className="c-icon" />
                             + 34 633 781 185
                            </div>
                        <div className="c-info-item">
                            <img src={Email} alt="" className="c-icon" />
                             paoloc9997@gmail.com
                            </div>
                        <div className="c-info-item">
                            <img src={Address} alt="" className="c-icon" />
                             Barcelona, 
                             {t("esp")}
                        </div>

                    </div>
                </div>
                <div className="c-right">
                    <p className="c-desc">
                        <b>{t("cta-2")}</b> {t("cta-3")}
                     </p>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <input type="text" placeholder={t("name")} name="user_name" required/>
                        <input type="text" placeholder={t("subject")} name="user_subject" required/>
                        <input type="text" placeholder={t("email")} name="user_email" required/>
                        <textarea rows="5" palceholder={t("message")} name="message" required/>
                        <button>{t("submit")}</button>
                        {done && t("thanks")}
                        
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact
